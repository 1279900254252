/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $(".toggle-categories").on('click', function(){
            $(".filters").slideToggle('500');
        });

        $(".mobile-nav-toggle").click(function(e){
          $(".mobile-navigation-container").toggleClass("animate");
          e.preventDefault();
        });



        $(".fade-in-section-home").addClass("is-visible");

         $(window).scroll(function () {
            $('.fade-in-section, .fade-in-section-left, .fade-in-section-right').each(function (i) {
                var bottom_of_object = $(this).offset().top + ($(this).outerHeight() * 0.05);
                var bottom_of_window = $(window).scrollTop() + $(window).height();
                if (bottom_of_window > bottom_of_object) {
                    $(this).addClass('is-visible');
                }
            });
          });



      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS


        var prevText;
        $('#welcome-text').hover(function(){

          prevText = $(this).text();

          
          $(this).text("Welcome to Sofreh Restaurant");

        }, function(){

           
            $(this).text(prevText);
        });

         $("#foodCarousel").slick({
            centerMode: true,
            infinite: true,
            centerPadding: '60px',
            arrows: true,
            slidesToShow: 5,
            prevArrow: "<a href='#' class='slick-prev'><span class='fa fa-chevron-circle-left fa-2x'></span></a>",
            nextArrow: "<a href='#' class='slick-next'><span class='fa fa-chevron-circle-right fa-2x'></span></a>",
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '40px',
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '40px',
                  slidesToShow: 1
                }
              }
            ]
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
         $("#foodCarousel").slick({
            centerMode: true,
            infinite: true,
            centerPadding: '60px',
            arrows: true,
            slidesToShow: 5,
            prevArrow: "<a href='#' class='slick-prev'><span class='fa fa-chevron-circle-left fa-2x'></span></a>",
            nextArrow: "<a href='#' class='slick-next'><span class='fa fa-chevron-circle-right fa-2x'></span></a>",
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '40px',
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: true,
                  centerMode: true,
                  centerPadding: '40px',
                  slidesToShow: 1
                }
              }
            ]
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'catering': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
         $("#foodCarousel").slick({
            centerMode: true,
            infinite: true,
            centerPadding: '60px',
            arrows: true,
            slidesToShow: 5,
            prevArrow: "<a href='#' class='slick-prev'><span class='fa fa-chevron-circle-left fa-2x'></span></a>",
            nextArrow: "<a href='#' class='slick-next'><span class='fa fa-chevron-circle-right fa-2x'></span></a>",
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  arrows: false,
                  centerMode: true,
                  centerPadding: '40px',
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  arrows: true,
                  centerMode: true,
                  centerPadding: '40px',
                  slidesToShow: 1
                }
              }
            ]
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
